import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, EntityTypeFieldDto, ObjectiveFieldDto } from '@shared/classes';
import { ObjectivesMappingService } from '@shared/services/mappings/entity/objectives-mapping.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { Subscription } from 'rxjs';
import { EntityTypesDataService } from '../../services/data/entity-types-data.service';

@Component({
  selector: 'app-objectives-item-form',
  templateUrl: './objectives-item-form.component.html',
  styleUrls: ['./objectives-item-form.component.scss'],
})
export class ObjectivesItemFormComponent extends BaseForm<ObjectiveFieldDto> implements OnInit {
  @Input() itemId: string = null;
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  orgList = [EntityTypeFieldDto.CategoryEnum.Organization];

  objList = [EntityTypeFieldDto.CategoryEnum.Objective];
  orgSubscriber: Subscription = null;
  constructor(
    public viewModeService: ViewModeService,
    private entityTypeRequestService: EntityTypesDataService,
    public mapperService: ObjectivesMappingService
  ) {
    super(viewModeService, 'OBJECTIVE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.formatGetChangedFormValues(this.getChangedFormValues().updateItems);
  }
  formatGetData() {
    let ret = { ...this.formGroup.getRawValue() };
    ret.type = ret.type.code;
    return ret;
  }

  setData(data: any) {
    if (this.orgSubscriber) this?.orgSubscriber?.unsubscribe();

    let patchVal = { ...data };
    if (data?.entityType) patchVal.type = { ...data.entityType };
    this.patchFormData(patchVal);
    this.data = data;

    if (this.itemId) {
      this.formGroup.get('type').disable();
    }
    !!!this?.data?.organization ? this.formGroup.get('parent').disable() : this.formGroup.get('parent').enable();

    this.orgSubscriber = this.formGroup?.controls?.organization?.valueChanges.subscribe((org) => {
      let parentControl = this.formGroup.get('parent');

      parentControl.setValue(null);

      !!!org ? parentControl.disable() : parentControl.enable();

      parentControl.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      // responsibility: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      description: new FormControl(null),
      // category: new FormControl(Entity.CategoryEnum.Objectives),
      organization: new FormControl(null, Validators.required),
      // tags: new FormControl(null),
      dynamics: new FormGroup({}),
    });
  }
  formatGetChangedFormValues(updateItems: { key: string; value: any }[]) {
    let items = [];
    updateItems.forEach((element) => {
      if (element.key == 'type') {
        element.value = element?.value?.code;
      }
      items.push(element);
    });
    return items;
  }
  entityType: EntityTypeFieldDto = null;
  entityTypeLoading: boolean = false;
  onEntityTypeChange(value) {
    // if(!!!value) return;
    // this.entityTypeLoading = true;
    // this.entityTypeRequestService.getByIdOrCode(value?.code, { showLoading: false, showMsg: false }).pipe(takeUntil(this.destroy$)).subscribe(
    //     {
    //         next: (res) => {
    //             this.entityType = res.data;
    //             this.entityTypeLoading = false;
    //         },
    //         error: (err) => {
    //             this.entityTypeLoading = false;
    //         }
    //     })
  }
  checkValueChanged(formCurrentData: any, key: string) {
    return this.data && (key == 'type' ? formCurrentData[key]?.code : formCurrentData[key]) != this.data[key];
  }
  // onSelectResponsibilityObj(event) {

  //     this.formGroup?.controls?.owner?.patchValue(event ? {
  //         name: event?.groupName,
  //         principleType: 'RESPONSIBILITY'
  //     } : {
  //         name: null,
  //         principleType: null,
  //     })
  // }
}
